<template>
  <div>
    <div class="row">
      <div class="col-3">
        <b-form-select
            v-model="month"
            :options="monthOptions"
            @change="reloadData()"
        ></b-form-select>
      </div>
      <div class="col-3">
        <button class="btn btn-success" @click="download">Скачать</button>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
       <table class="table table-bordered">
         <thead>
         <tr>
           <th>№</th>
           <th>Ученик</th>
           <th v-for="day in days" :key="day.day">
             {{ day.day }}
           </th>
         </tr>
         </thead>
         <tbody>
         <tr v-for="(student, k) in students" :key="k">
           <td>{{ k+1}}</td>
           <td>{{student.studentName}}</td>
           <td v-for="day in days" :key="day.day">
             <template v-if="student.absenceDays.includes(day.day)">Н</template>
           </td>
         </tr>
         
         <tr>
           <td></td>
           <td>Всего присутсвовало</td>
           <td v-for="day in days" :key="day.day">
             {{day.totalPresence}}
           </td>
         </tr>
         </tbody>
       </table>
      </div>

      
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
export default {
  name: "AbsenceLogMonthlyReport",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      month: DictionariesService.currentMonth(),
      monthOptions: DictionariesService.monthOptions(),
      days:[],
      students:[],
    };
  },
  mounted() {
    
  },
  methods: {    
    reloadData(){
      let $this = this;
      let filter = {groupId: this.groupId, month: this.month, export: false};
      ApiService.postSecured("absenceLog/groupMonthlyAbsenceLogReport", filter).then(({data}) => {
        
        $this.days = data.days;
        $this.students = data.students;
        
      }).catch(({response}) => {
        console.log(response);
      });
    },
    download(){
      let filter = {groupId: this.groupId, month: this.month, export: true};
      ApiService.downloadSecured("absenceLog/groupMonthlyAbsenceLogReport", filter).then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Дисциплинарный дневник.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
  }
};
</script>
