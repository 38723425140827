<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <button class="btn btn-success" @click="openAddWindow">
          {{ $t("common.new") }}
        </button>
      </div>
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1" v-if="!showEdit">
       <table class="table table-bordered">
         <thead>
         <tr>
           <th>Дата</th>
           <th>Присутствуют все</th>
           <th>Количество отсутвующих</th>
           <th>Фио</th>
         </tr>
         </thead>
         <tbody>
         <tr v-for="(date, k) in dates" :key="k">
           <td>{{date.dateString}}</td>
           <td><template v-if="date.everyoneIsPresent === true">Да</template></td>
           <td>{{date.count}}</td>
           <td>{{date.studentNames}}</td>
         </tr>
         </tbody>
       </table>
      </div>

      <div class="col-lg-12" v-if="showEdit">
        <b-form-datepicker
            v-model="date" 
            class="mb-2" 
            @context="changeDate"
            locale="ru"
            start-weekday="1"
            :date-disabled-fn="dateDisabled">
        </b-form-datepicker>
        <b-form-checkbox
            v-model="everyoneIsPresent"
            @change="changeEveryoneIsPresent"
            v-if="date != null"
        >
          Присутствуют все
        </b-form-checkbox>
        
        <table class="table table-bordered" v-if="date != null && everyoneIsPresent !== true" :reDrawTable="reDrawTable">
          <thead>
          <tr>
            <th>Фио</th>
            <th>Причина отсутсвия</th>
            <th>Опоздал</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="student in students" :key="student.id">
            <td>{{student.name}}</td>
            <td>
              <b-form-select
                  v-model="absenceLog[student.id]"
                  :options="absenceReasons"
                  v-on:change="
                              changeAbsence(
                                student.id,
                                $event
                              )
                            "
                  :style="{ width: '100px' }"
              ></b-form-select>
              <button class="btn btn btn-icon btn-success btn-danger" @click="deleteRecord(student.id)" style="margin-left: 10px">
                <i class="flaticon2-trash"></i>
              </button>
            </td>
            <td>
              <b-form-input v-model="absenceLogLateMinute[student.id]"
                            v-on:change="
                              changeLateMinute(
                                student.id,
                                $event
                              )
                            "
              ></b-form-input>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="pull-right"><button class="btn btn-danger" @click="closeEdit">Закрыть</button></div>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";
export default {
  name: "AbsenceLog",
  components: {
  },
  props:{
    groupId:String
  },
  data() {
    return {
      date: null,
      dates:[],
      students:[],
      aa:null,
      absenceLog:{},
      absenceLogLateMinute:{},
      absenceReasons:[],
      reDrawTable: 0,
      showEdit: false,
      everyoneIsPresent: null,
      
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0;
    },
    changeDate() {
      this.loadAbsence();
    },
    changeEveryoneIsPresent(/*event*/) {
/*      let val = event;

      if(val == 0 ){
        return;
      }*/
      let data = {
        groupId: this.groupId,
        date: this.date,
        everyoneIsPresent: this.everyoneIsPresent,
      };
      let $this = this;
      ApiService.postSecured("absenceLog/saveEveryoneIsPresent", data)
          .then(function () {
            $this.loadAbsence();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },

    changeAbsence(studentId, event) {
      let val = event;

      if(val == 0 ){
        return;
      }
      
      let data = {
        studentId: studentId,
        date: this.date,
        reasonId: val,
      };

      ApiService.postSecured("absenceLog/saveAbsence", data)
          .then(function () {
            
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    deleteRecord(studentId) {

      if (!confirm("Удалить?")) {
        return;
      }

      let $this = this;
      let data = {
        studentId: studentId,
        date: this.date,
      };
      
      ApiService.postSecured("absenceLog/delete", data)
          .then(function () {
            $this.loadAbsence();
          })
          .catch(({response}) => {
            console.log(response);
          });
    },  
    changeLateMinute(studentId, event) {
      let val = event;

      let data = {
        studentId: studentId,
        date: this.date,
        beingLate: val,
      };

      ApiService.postSecured("absenceLog/saveLateMinute", data)
          .then(function () {
            
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadAbsence(){
      let $this = this;
      
      ApiService.querySecured("absenceLog/getForGroupAndDate", {
        params: {groupId: this.groupId, date: this.date}
      }).then(({data}) => {
        $this.everyoneIsPresent = data.everyoneIsPresent;
        $this.absenceLog = [];
        $this.students = data.students;
        
        for (let i in data.students){
          let student = data.students[i];
          $this.absenceLog[student.id] = null
          $this.absenceLogLateMinute[student.id] = null
        }
        
        for (let i in data.items){
          let item = data.items[i];
          $this.absenceLog[item.studentId] = item.reasonId;
          $this.absenceLogLateMinute[item.studentId] = item.beingLate;
        }
      }).catch(({response}) => {
        console.log(response);
      }).finally(()=>{
        $this.reDrawTable = Math.random();
      });
    },
    reloadData(){
      let $this = this;
      ApiService.querySecured("students/namesForGroup", {
        params: {groupId: this.groupId}
      }).then(({data}) => {
        $this.students = data;
        for (let i in $this.students){
          let student = $this.students[i];
          $this.absenceLog[student.id] = null
          $this.absenceLogLateMinute[student.id] = null
        }
        
        $this.loadAbsence();
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/absenceReasons").then(({data}) => {
        data.dictionary.unshift({ value: 0, text: '' });
        $this.absenceReasons = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("absenceLog/list", {params: {groupId: this.groupId}}).then(({data}) => {
        $this.dates = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    openAddWindow(){
      this.showEdit = true;
    },
    closeEdit(){
      this.showEdit = false;
      this.reloadData();
    }
  }
};
</script>
